import './App.css';
import * as React from "react"
import {initializeApp} from 'firebase/app';
import { getAnalytics, logEvent  } from "firebase/analytics";
import {getDatabase, ref, get, query, limitToFirst, startAt, orderByChild, endAt, equalTo, child} from "firebase/database";

import bbrefLogo from "./images/bbref-logo.png"
import fgLogo from "./images/fangraphs-logo.png"
import art from "./images/tedstammdodger.png"

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Autocomplete,
    Button, debounce,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    styled,
    Tab,
    Tabs,
    TextField
} from "@mui/material"
const StyledAutocomplete = styled(Autocomplete)({
    "& label.Mui-focused": {
        color: "black",
    },
    "& label": {
        color: "black",
    },
    "& .MuiAutocomplete-popupIndicator": {
        color: "black"
    },
    "& .MuiAutocomplete-inputRoot": {
        color: "black",
        background: "white",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black"
        }
    }
});

const StyledFormControl = styled(FormControl)({
    width: 200
})

const firebaseConfig = {
    apiKey: "AIzaSyBWReNoWPCRjv6aIJzB7p_n2fg_sNUPu-w",
    authDomain: "era-adjustment-d.firebaseapp.com",
    databaseURL: "https://era-adjustment-d-default-rtdb.firebaseio.com",
    projectId: "era-adjustment-d",
    storageBucket: "era-adjustment-d.appspot.com",
    messagingSenderId: "636456547738",
    appId: "1:636456547738:web:0a3c6be2908ea4de247623",
    measurementId: "G-RZ84HZ1VKD"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const players = ref(database, "players")
const eb_rankings = ref(database, "eb_rankings")
const ef_rankings = ref(database, "ef_rankings")
const articles = ref(database, "articles")
const rankingsHash = "rankings"
const aggregateStats = ["pa", "ab", "h", "hr", "bb", "bwar", "fwar", "ip", "so"]
const maxAvgStats = ["ba", "obp"]

class App extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            articles: [],
            ebRankings: [],
            efRankings: [],
            searchResults: [],
            selectedPlayer: null,
            selectedKey: null,
            startYear: null,
            endYear: null,
            consecutiveBattingYears: null,
            consecutivePitchingYears: null,
            battingMetric: null,
            pitchingMetric: null,
            selectedTab: 0,
            ebRankSelected: true,
            filters: {}
        }
    }

    componentDidMount() {
        get(eb_rankings).then((snapshot) => {
            if (snapshot.exists()) {
                this.setState({
                    ebRankings: snapshot.val()
                })
            }
        }).catch((error) => {
            console.error(error);
        });

        get(ef_rankings).then((snapshot) => {
            if (snapshot.exists()) {
                this.setState({
                    efRankings: snapshot.val()
                })
            }
        }).catch((error) => {
            console.error(error);
        });

        get(articles).then((snapshot) => {
            if (snapshot.exists()) {
                this.setState({
                    articles: snapshot.val()
                })
            }
        }).catch((error) => {
            console.error(error);
        });

        window.addEventListener("hashchange", (value) => {
            if (value.newURL.includes("#" + rankingsHash)) {
                this.setState({
                    searchResults: [],
                    selectedPlayer: null,
                    selectedKey: null
                })
            } else {
                this.setState({
                    startYear: null,
                    endYear: null,
                    consecutiveBattingYears: "",
                    consecutivePitchingYears: "",
                    battingMetric: null,
                    pitchingMetric: null,
                    filters: {}
                })
            }
        })
    }

    render() {
        return <div>
            {this.renderHeader()}
            {this.renderContent()}
        </div>
    }

    renderHeader() {
        return <AppBar position="sticky" style={{ background: "#faefd2" }}>
            <Toolbar>
                <img title={"Dodger Designator © Ted Stamm Estate"} src={art} alt="Era Adjusted Baseball Stats" height="50px" style={{marginRight: "10px", cursor: "pointer"}} onClick={() => window.location.hash = rankingsHash}/>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    onClick={() => window.location.hash = rankingsHash}
                    color={"#000"}
                    sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, cursor: "pointer" }}>
                    Era Adjusted Baseball Stats
                </Typography>
                <StyledAutocomplete
                    options={ this.state.searchResults }
                    size="small"
                    sx={{ width: 600, marginTop: "10px", marginBottom: "10px" }}
                    freeSolo={true}
                    renderInput={(params) => <TextField {...params} placeholder="Search by last name..."/>}
                    getOptionLabel={(option) => option["name"] + " (" + first(Object.keys(option["seasons"])) + "-" + last(Object.keys(option["seasons"])) + ")"}
                    onChange={((event, value) => {
                        if (event.target.value === 0) {
                            this.handlePlayerClick(value["bbref_id"], "search")
                        }
                    })}
                    onInputChange={debounce((event, value) =>{
                        this.handleSearch(event.target.value)
                    }, 250)}
                />
            </Toolbar>
        </AppBar>
    }

    renderContent() {
        if (this.state.selectedPlayer != null) {
            window.location.hash = this.state.selectedPlayer["name"];
            return <div className="content">
                {this.renderPlayerPage()}
            </div>
        } else {
            window.location.hash = rankingsHash;
            return <div className="content">
                {this.renderRankings()}
                {this.renderArticles()}
            </div>
        }
    }

    renderArticles() {
        const articles =  <div>
            For a fun read containing more results and some analyses, see:
            <br/>
<a href={"https://htmlpreview.github.io/?https://github.com/ecklab/era-adjustment-app-supplement/blob/main/writeups/era_adjusted_V2_I.html"}>The Full House Model for cross-era comparisons of baseball players (results and fun digressions version 2.0)</a>
            <br/>
            <br/>
            For construction of the talent pool, see
            <br/>
            <a href={"https://htmlpreview.github.io/?https://github.com/ecklab/era-adjustment-app-supplement/blob/main/writeups/MLBeligiblepop.html"}>Estimation of the MLB talent pool; Supplement to Comparing baseball players across eras via the novel Full House Model</a>
            <br/>
            <br/>
            For technical details on the Full House Model, see:
            <br/>
            <a href={"https://arxiv.org/abs/2207.11332"}>Comparing baseball players across eras via novel Full House Modeling</a>
            <br/>
            <br/>
            This is version 2.0. For more information on versions of the project, see: 
            <br/>
            <a href = {"https://github.com/ecklab/era-adjustment-app-supplement"}>Supplemental materials for our era-adjusted baseball statistics</a>
            <br/>
            <br/>
            For background on why WAR and other baseball statistics are not era-adjusted, see:
            <br/>
            <a href = {"https://community.fangraphs.com/challenging-war-and-other-statistics-as-era-adjustment-tools/"}>Challenging WAR and Other Statistics as Era-Adjustment Tools</a>
            <br/>
            <br/>
            <br/>
            Image Credit: @ <a href={"https://tedstamm.com/"}>Ted Stamm</a>
        </div>

        let source = this.state.ebRankSelected ? "Fangraphs" : "BBREF"

        return <div style={{marginLeft: "50px", width: "33%"}}>

            <Button
                variant="contained"
                onClick={() => {
                    this.setState({
                        ebRankSelected: !this.state.ebRankSelected
                    })
                }}>
                {"Toggle to " + source + " WAR Calculation"}
            </Button>
            <h3>About</h3>
            <p>This website contains several era-adjusted statistics for baseball players. This page contains a top 100 list ordered by career era-adjusted wins above replacement from baseball reference (ebWAR). You can toggle to a top 100 list ordered by career era-adjusted wins above replacement from fangraphs (efWAR). You can visit player pages that contain era-adjusted and traditional statistics by clicking on the player's name in either top 100 list or by using the search bar.</p>
          
<p>These era-adjusted statistics are obtained from Full House Modeling. This model computes era-adjusted statistics through a principled balancing of how well a player performed "vs their peers" and the size of the MLB talent pool. Under this model, great all-time statistics requires that an MLB player is both better than their peers and played during a time in which the talent pool is large. In this way, the model constructs an even playing field that extends across eras.</p>
            <br/>
            <h4>Related Content:</h4>
            {articles}
        </div>
    }

    renderRankings() {
        let sorted

        if (this.state.ebRankSelected) {
            sorted = this.state.ebRankings.sort(((a, b) => (a.ebWAR > b.ebWAR) ? -1 : 1))
        } else {
            sorted = this.state.efRankings.sort(((a, b) => (a.efWAR > b.efWAR) ? -1 : 1))
        }

        const top100 = sorted.map((player, index) => {
            let ranking = index + 1
            let name = player.name
            let war = this.state.ebRankSelected ? player.ebWAR : player.efWAR
            return <tr onClick={() => this.handlePlayerClick(player.key_bbref, "rankings")}>
                <td>{ranking}</td>
                <td>{name}</td>
                <td>{war}</td>
            </tr>
        })

        const warTitle = this.state.ebRankSelected ? "ebWAR" : "efWAR"
        const warSource = this.state.ebRankSelected ? "BBRef" : "Fangraphs"

        return <table style={{width: "100%"}}>
            <th>Rank</th>
            <th>Player</th>
            <th title={"Era-adjusted " + warSource + " War"} style={{cursor: "pointer", width:"200px"}}>{warTitle}</th>
            {top100}
        </table>
    }

    renderPlayerPage() {
        let player = this.state.selectedPlayer
        const battingSection = <div className={"batting"}>
            {this.renderEraAdjustedBattingStats(player)}
            {this.renderActualBattingStats(player)}
        </div>
        const pitchingSection = <div className={"pitching"}>
            {this.renderEraAdjustedPitchingStats(player)}
            {this.renderActualPitchingStats(player)}
        </div>

        let tabBar =
            <div style={{clear:"left"}}>
                <Tabs value={this.state.selectedTab} onChange={(event) => {
                    this.setState({
                        selectedTab: event.target.tabIndex
                    })
                }}>
                    <Tab label="Pitching" tabIndex={0}/>
                    <Tab label="Batting" tabIndex={1}/>
                </Tabs>
            </div>

        let statsSection
        let currentSection = this.state.selectedTab === 0 ? pitchingSection : battingSection
        if (player["Position"] != null && player["Position"].toLowerCase().includes("pitcher")) {
            statsSection = <div>
                {tabBar}
                {currentSection}
            </div>
        } else {
            statsSection = <div>
                {battingSection}
            </div>
        }
        return <div>
            <div className={"filters"}>
                <div class="container">
                    <div class="bio-container">
                        {this.renderBio(player)}
                    </div>
                    <div style = {{clear: "left"}}>
                        <h3>Filters:</h3>
                        {this.renderFilters(player)}
                    </div>                    
                </div>
            </div>
            <div className={"stats"}>
                <div style={{clear: "left", paddingTop: "50px"}} />
                {statsSection}
            </div>
        </div>
    }

    renderFilters(player) {
        const yearOptions = Object.keys(player["seasons"]).map((season) => {
            return <MenuItem value={season} >{season}</MenuItem>
        })
        const battingOptions = ["PA", "AB", "H", "HR", "BB", "BA", "OBP", "bWAR", "fWAR", "Era Adjusted PA", "Era Adjusted AB", "Era Adjusted H", "Era Adjusted HR", "Era Adjusted BB", "Era Adjusted BA", "Era Adjusted OBP", "Era Adjusted bWAR", "Era Adjusted fWAR"].map((stat) => {
            return <MenuItem value={stat}>{stat}</MenuItem>
        })
        const pitchingOptions = ["IP", "ERA", "SO", "bWAR", "fWAR", "Era Adjusted IP", "Era Adjusted ERA", "Era Adjusted SO", "Era Adjusted bWAR", "Era Adjusted fWAR"].map((stat) => {
            return <MenuItem value={stat}>{stat}</MenuItem>
        })
        const reset = Object.values(this.state.filters).length === 0 ? null : <div>
            <br/>
            <Button
                variant="outlined"
                onClick={() => {
                    this.resetFilters()
                }}>
                Reset Filters
            </Button>
        </div>
        let activeFilter
        if (this.state.selectedTab === 1) {
            activeFilter = <div>
                <StyledFormControl>
                    <TextField id="outlined-basic"
                               label="Top N Years"
                               variant="outlined"
                               InputLabelProps={{shrink: true}}
                               defaultValue={null}
                               value={this.state.consecutiveBattingYears}
                               onChange={(event) => {
                                   this.setState({
                                       consecutiveBattingYears: event.target.value
                                   })
                               }}
                    />
                </StyledFormControl>
                <br/><br/>
                <StyledFormControl>
                    <TextField
                        onChange={(event) => {
                            this.setState({
                                battingMetric: event.target.value
                            })
                        }}
                        select
                        InputLabelProps={{shrink: true}}
                        value={this.state.battingMetric ? this.state.battingMetric : ""}
                        label="By Batting Metric">
                        { battingOptions }
                    </TextField>
                </StyledFormControl>
            </div>
        } else {
            activeFilter = <div>
                <StyledFormControl>
                    <TextField id="outlined-basic"
                               label="Top N Years"
                               variant="outlined"
                               defaultValue={null}
                               value={this.state.consecutivePitchingYears}
                               InputLabelProps={{shrink: true}}
                               onChange={(event) => {
                                   this.setState({
                                       consecutivePitchingYears: event.target.value
                                   })
                               }}
                    />
                </StyledFormControl>
                <br/><br/>
                <StyledFormControl>
                    <TextField
                        onChange={(event) => {
                            this.setState({
                                pitchingMetric: event.target.value
                            })
                        }}
                        select
                        InputLabelProps={{shrink: true}}
                        value={this.state.pitchingMetric ? this.state.pitchingMetric : ""}
                        label="By Pitching Metric">
                        { pitchingOptions }
                    </TextField>
                </StyledFormControl>
            </div>
        }
        return <div>
            <StyledFormControl>
                <TextField
                    onChange={(event) => {
                        this.setState({
                            startYear: event.target.value
                        })
                    }}
                    select
                    InputLabelProps={{shrink: true}}
                    value={this.state.startYear ? this.state.startYear : ""}
                    label="Start Year">
                    {yearOptions}
                </TextField>
            </StyledFormControl>
            <br/><br/>
            <StyledFormControl>
                <TextField
                    onChange={(event) => {
                        this.setState({
                            endYear: event.target.value
                        })
                    }}
                    select
                    InputLabelProps={{shrink: true}}
                    value={this.state.endYear ? this.state.endYear : ""}
                    label="End Year">
                    {yearOptions}
                </TextField>
            </StyledFormControl>
            <br/><br/><br/>
            {activeFilter}
            <br/><br/>
            <Button
                variant="contained"
                style={{whiteSpace: "nowrap"}}
                onClick={() => {
                    this.applyFilters()
                }}>
                Apply Filters
            </Button>
            {reset}
        </div>
    }

    renderBio(player) {
        let bbrefLink = this.createBbrefLink(this.state.selectedKey)
        let fgLink = this.createFangraphsLink(player["name"], player["fangraphs_id"])

        let seasons = Object.keys(player["seasons"])

        let fWar = 0
        let efWar = 0
        let bWar = 0
        let ebWar = 0

        seasons.forEach(season => {
            let currentSeason = player["seasons"][season]
            if ("adjusted" in currentSeason) {
                efWar += parseFloat(currentSeason["adjusted"]["b_ef_war"] ?? "0")
                efWar += parseFloat(currentSeason["adjusted"]["p_ef_war"] ?? "0")
                ebWar += parseFloat(currentSeason["adjusted"]["b_eb_war"] ?? "0")
                ebWar += parseFloat(currentSeason["adjusted"]["p_eb_war"] ?? "0")
            }
            if ("raw" in currentSeason) {
                fWar += parseFloat(currentSeason["raw"]["b_f_war"] ?? "0")
                fWar += parseFloat(currentSeason["raw"]["p_f_war"] ?? "0")
                bWar += parseFloat(currentSeason["raw"]["b_b_war"] ?? "0")
                bWar += parseFloat(currentSeason["raw"]["p_b_war"] ?? "0")
            }
        })

        return <div className={"bio"}>
            <div>
                <div style={{float: "left", fontSize: "x-large", fontWeight: "bold"}}>
                    {player["name"]}
                </div>
            </div>
            <br/>
            <br/>
            <table style={{marginTop: "10px"}}>
                <tr>
                    <td>Full Name:</td>
                    <td>{player["FullName"]}</td>
                </tr>
                <tr>
                    <td>Position:</td>
                    <td>{player["Position"]}</td>
                </tr>
                <tr>
                    <td>Bats</td>
                    <td>{player["Bats"]}</td>
                </tr>
                <tr>
                    <td>Throws</td>
                    <td>{player["Throws"]}</td>
                </tr>
            </table>

            <table style={{marginTop: "20px"}}>
            <div class="container">
                <div class="image">
                    <img src={bbrefLogo} alt="BBRef Link" style={{paddingTop: "4px", paddingBottom: "4px", height: "24px", marginLeft: "10px", marginRight: "1px"}}/>
                </div>
                <div class="text">
                    <a href={bbrefLink} target="_blank">{player["name"]}</a>
                </div>
            </div>
                <tr>
                    <td>Era Adjusted bWAR</td>
                    <td>{ebWar.toFixed(1)}</td>
                </tr>
                <tr>
                    <td>bWAR</td>
                    <td>{bWar.toFixed(1)}</td>
                </tr>
            </table>
                
            <table style={{marginTop: "20px"}}>       
            <div>
                <div class="container">
                    <div class = "image">
                        <img src={fgLogo} alt="Fangraphs Link" style={{paddingTop: "4px", paddingBottom: "4px", height: "24px", marginLeft: "10px", marginRight: "1px"}}/>
                    </div>
                    <div class = "text">
                        <a href={fgLink} target="_blank">{player["name"]}</a>
                    </div>
                </div>
            </div>    
                <tr>
                    <td>Era Adjusted fWAR</td>
                    <td>{efWar.toFixed(1)}</td>
                </tr>                
                <tr>
                    <td>fWAR</td>
                    <td>{fWar.toFixed(1)}</td>
                </tr>
            </table>
        </div>
    }

    renderEraAdjustedBattingStats(player) {
        const seasons = this.getAvailableYears().map((season) => {
            let currentSeason = player["seasons"][season]
            let adjustedStats = currentSeason["adjusted"]
            if (adjustedStats == null || adjustedStats["b_ef_war"] == null || adjustedStats["b_eb_war"] == null) {
                return null
            }
            return <tr>
                <td>{season}</td>
                <td>{adjustedStats["pa"] ?? "0"}</td>
                <td>{adjustedStats["ab"] ?? "0"}</td>
                <td>{adjustedStats["h"] ?? "0"}</td>
                <td>{adjustedStats["hr"] ?? "0"}</td>
                <td>{adjustedStats["bb"] ?? "0"}</td>
                <td>{parseFloat(adjustedStats["avg"] ?? "0").toFixed(3)}</td>
                <td>{parseFloat(adjustedStats["obp"] ?? "0").toFixed(3)}</td>
                <td>{parseFloat(adjustedStats["b_eb_war"] ?? "0").toFixed(1)}</td>
                <td>{parseFloat(adjustedStats["b_ef_war"] ?? "0").toFixed(1)}</td>
            </tr>
        })

        let warningLabel

        if (this.getEraAdjustedBattingYears() !== seasons.length) {
            warningLabel = <p>This player may be missing era-adjusted seasons due to career trimming</p>
        } else {
            warningLabel = <p></p>
        }
        return <div>
            <h3>Era Adjusted Batting Stats</h3>
            {warningLabel}
            <table>
                <th style={{width: "180px"}}>Year</th>
                <th title={"Plate appearances"}>PA</th>
                <th title={"At bats"}>AB</th>
                <th title={"Hits"}>H</th>
                <th title={"Home Runs"}>HR</th>
                <th title={"Bases on Balls/Walks"}>BB</th>
                <th title={"Batting Average (H/AB)"}>BA</th>
                <th title={"On Base Percentage ((H + BB + HBP)/(AB + BB + HBP + SF))"}>OBP</th>
                <th title={"Era-adjusted Wins Above Replacement computed by BBref"}>ebWAR</th>
                <th title={"Era-adjusted Wins Above Replacement computed by FanGraphs"}>efWAR</th>
                {seasons}
                {this.renderEraAdjustedAggregateBatting(player)}
            </table>
        </div>
    }

    renderEraAdjustedAggregateBatting(player) {
        try {
            let seasons = this.getAvailableYears()

            let pa = 0
            let ab = 0
            let h = 0
            let hr = 0
            let bb = 0
            let hbp = 0
            let sf = 0
            let efWAR = 0
            let ebWAR = 0

            seasons.forEach(season => {
                let currentSeason = player["seasons"][season]['adjusted']
                if (currentSeason !== undefined) {
                    pa += parseInt(currentSeason["pa"] ?? "0")
                    ab += parseInt(currentSeason["ab"] ?? "0")
                    h += parseInt(currentSeason["h"] ?? "0")
                    hr += parseInt(currentSeason["hr"] ?? "0")
                    bb += parseInt(currentSeason["bb"] ?? "0")
                    hbp += parseInt(currentSeason["hbp"] ?? "0")
                    sf += parseInt(currentSeason["sf"] ?? "0")
                    efWAR += parseFloat(currentSeason["b_ef_war"] ?? "0")
                    ebWAR += parseFloat(currentSeason["b_eb_war"] ?? "0")
                }
            })

            let aggregate_dict = this.calculate_aggregate_batting_stats({
                h: h,
                bb: bb,
                hbp: hbp,
                pa: pa,
                ab: ab,
                hr: hr,
                sf: sf
            })

            let avg = aggregate_dict["avg"]
            let obp = aggregate_dict["obp"]
            let seasonCount = this.getEraAdjustedBattingYears()

            return [<tr className={"foot"}>
                <td>Era Adjusted Totals</td>
                <td>{pa}</td>
                <td>{ab}</td>
                <td>{h}</td>
                <td>{hr}</td>
                <td>{bb}</td>
                <td>{avg.toFixed(3)}</td>
                <td>{obp.toFixed(3)}</td>
                <td>{ebWAR.toFixed(1)}</td>
                <td>{efWAR.toFixed(1)}</td>
            </tr>,
                <tr className={"foot"}>
                    <td>Era Adjusted Averages</td>
                    <td>{(pa / seasonCount).toFixed(0)}</td>
                    <td>{(ab / seasonCount).toFixed(0)}</td>
                    <td>{(h / seasonCount).toFixed(0)}</td>
                    <td>{(hr / seasonCount).toFixed(0)}</td>
                    <td>{(bb / seasonCount).toFixed(0)}</td>
                    <td>{avg.toFixed(3)}</td>
                    <td>{obp.toFixed(3)}</td>
                    <td>{(ebWAR / seasonCount).toFixed(1)}</td>
                    <td>{(efWAR / seasonCount).toFixed(1)}</td>
                </tr>]
        } catch (e) {
            return <div>ERROR</div>
        }
    }

    renderEraAdjustedPitchingStats(player) {
        const seasons = this.getAvailableYears().map((season) => {
            let currentSeason = player["seasons"][season]
            let adjustedStats = currentSeason["adjusted"]

            if (adjustedStats == null || adjustedStats["p_ef_war"] == null || adjustedStats["p_eb_war"] == null) {
                return null
            }

            return <tr>
                <td>{season}</td>
                <td>{parseFloat(adjustedStats["ip"] ?? "0").toFixed(0)}</td>
                <td>{parseFloat(adjustedStats["era"] ?? "0").toFixed(2)}</td>
                <td>{adjustedStats["so"]}</td>
                <td>{parseFloat(adjustedStats["p_eb_war"] ?? "0").toFixed(1)}</td>
                <td>{parseFloat(adjustedStats["p_ef_war"] ?? "0").toFixed(1)}</td>
            </tr>
        })

        let warningLabel

        if (this.getEraAdjustedPitchingYears() !== seasons.length) {
            warningLabel = <p>This player may be missing era-adjusted seasons due to lack of sample size</p>
        } else {
            warningLabel = <p></p>
        }

        if (seasons.filter(x => !!x).length === 0) {
            return null
        } else {
            return <div>
                <h3>Era Adjusted Pitching Stats</h3>
                {warningLabel}
                <table>
                    <th style={{width: "180px"}}>Year</th>
                    <th title={"Innings Pitched"}>IP</th>
                    <th title={"Earned Run Average (9 * ER / IP)"}>ERA</th>
                    <th title={"Strike Outs"}>SO</th>
                    <th title={"Era-adjusted Wins Above Replacement computed by BBRef"}>ebWAR</th>
                    <th title={"Era-adjusted Wins Above Replacement computed by FanGraphs"}>efWAR</th>
                    {seasons}
                    {this.renderEraAdjustedAggregatePitching(player)}
                </table>
            </div>
        }
    }

    renderEraAdjustedAggregatePitching(player) {
        let ip = 0
        let era = 0
        let er = 0
        let so = 0
        let ebWAR = 0
        let efWAR = 0
        let seasons = this.getAvailableYears()
        let seasonCount = this.getEraAdjustedPitchingYears()

        for (const season of seasons) {
            let currentSeason = player["seasons"][season]["adjusted"]
            if (currentSeason == null || currentSeason["p_ef_war"] == null || currentSeason["p_eb_war"] == null) {
                continue
            }
            ip += parseFloat(currentSeason["ip"] ?? "0")
            era += parseFloat(currentSeason["era"] ?? "0")
            er += parseFloat(currentSeason["er"] ?? "0")
            so += parseInt(currentSeason["so"] ?? "0")
            efWAR += parseFloat(currentSeason["p_ef_war"] ?? "0")
            ebWAR += parseFloat(currentSeason["p_eb_war"] ?? "0")
        }
        if (ip === 0) {
            return null
        } else {
            return [
                <tr className={"foot"}>
                    <td>Era Adjusted Totals</td>
                    <td>{ip.toFixed(0)}</td>
                    <td>{(((er/ip) * 9)).toFixed(2)}</td>
                    <td>{so}</td>
                    <td>{ebWAR.toFixed(1)}</td>
                    <td>{efWAR.toFixed(1)}</td>
                </tr>,
                <tr className={"foot"}>
                    <td>Era Adjusted Averages</td>
                    <td>{(ip / seasonCount).toFixed(0)}</td>
                    <td>{(((er/ip) * 9)).toFixed(2)}</td>
                    <td>{(so / seasonCount).toFixed(0)}</td>
                    <td>{(ebWAR / seasonCount).toFixed(1)}</td>
                    <td>{(efWAR / seasonCount).toFixed(1)}</td>
                </tr>
            ]
        }
    }

    renderActualBattingStats(player) {
        const seasons = this.getAvailableYears().map((season) => {
            let currentSeason = player["seasons"][season]
            let stats = currentSeason["raw"]

            if (stats == null || stats["b_f_war"] == null || stats["b_b_war"] == null) {
                return null
            }

            return <tr>
                <td>{season}</td>
                <td>{stats["pa"] ?? "0"}</td>
                <td>{stats["ab"] ?? "0"}</td>
                <td>{stats["h"] ?? "0"}</td>
                <td>{stats["hr"] ?? "0"}</td>
                <td>{stats["bb"] ?? "0"}</td>
                <td>{parseFloat(stats["avg"] ?? "0").toFixed(3)}</td>
                <td>{parseFloat(stats["obp"] ?? "0").toFixed(3)}</td>
                <td>{parseFloat(stats["b_b_war"] ?? "0").toFixed(1)}</td>
                <td>{parseFloat(stats["b_f_war"] ?? "0").toFixed(1)}</td>
            </tr>
        })
        return <div>
            <h3>Batting Stats</h3>
            <table>
                <th style={{width: "180px"}}>Year</th>
                <th title={"Plate appearances"}>PA</th>
                <th title={"At bats"}>AB</th>
                <th title={"Hits"}>H</th>
                <th title={"Home Runs"}>HR</th>
                <th title={"Bases on Balls/Walks"}>BB</th>
                <th title={"Batting Average (H/AB)"}>BA</th>
                <th title={"On Base Percentage ((H + BB + HBP)/(AB + BB + HBP + SF))"}>OBP</th>
                <th title={"Wins Above Replacement computed by BBref"}>bWAR</th>
                <th title={"Wins Above Replacement computed by FanGraphs"}>fWAR</th>
                {seasons}
                {this.renderAggregateBatting(player)}
            </table>
        </div>
    }

    renderAggregateBatting(player) {
        let seasons = this.getAvailableYears()

        let pa = 0
        let ab = 0
        let h = 0
        let hr = 0
        let bb = 0
        let hbp = 0
        let sf = 0
        let bWAR = 0
        let fWAR = 0

        seasons.forEach(season => {
            let currentSeason = player["seasons"][season]['raw']
            pa += parseInt(currentSeason["pa"] ?? "0")
            ab += parseInt(currentSeason["ab"] ?? "0")
            h += parseInt(currentSeason["h"] ?? "0")
            hr += parseInt(currentSeason["hr"] ?? "0")
            bb += parseInt(currentSeason["bb"] ?? "0")
            hbp += parseInt(currentSeason["hbp"] ?? "0")
            sf += parseInt(currentSeason["sf"] ?? "0")
            bWAR += parseFloat(currentSeason["b_b_war"] ?? "0")
            fWAR += parseFloat(currentSeason["b_f_war"] ?? "0")
        })

        let aggregate_dict = this.calculate_aggregate_batting_stats({h: h, bb: bb, hbp: hbp, pa: pa, ab: ab, hr: hr, sf: sf})

        let avg = aggregate_dict["avg"]
        let obp = aggregate_dict["obp"]
        return [
            <tr className={"foot"}>
                <td>Actual Totals</td>
                <td>{pa}</td>
                <td>{ab}</td>
                <td>{h}</td>
                <td>{hr}</td>
                <td>{bb}</td>
                <td>{avg.toFixed(3)}</td>
                <td>{obp.toFixed(3)}</td>
                <td>{bWAR.toFixed(1)}</td>
                <td>{fWAR.toFixed(1)}</td>
            </tr>,
            <tr className={"foot"}>
                <td>Actual Averages</td>
                <td>{(pa / seasons.length).toFixed(0)}</td>
                <td>{(ab / seasons.length).toFixed(0)}</td>
                <td>{(h / seasons.length).toFixed(0)}</td>
                <td>{(hr / seasons.length).toFixed(0)}</td>
                <td>{(bb / seasons.length).toFixed(0)}</td>
                <td>{avg.toFixed(3)}</td>
                <td>{obp.toFixed(3)}</td>
                <td>{(bWAR / seasons.length).toFixed(1)}</td>
                <td>{(fWAR / seasons.length).toFixed(1)}</td>
            </tr>
        ]
    }

    renderActualPitchingStats(player) {
        const seasons = this.getAvailableYears().map((season) => {
            let currentSeason = player["seasons"][season]
            let stats = currentSeason["raw"]

            if (stats == null || stats["p_f_war"] == null) {
                return null
            }

            let ip = stats["ip"] ?? "0"
            let k = parseFloat(stats["k"] ?? "0")
            let bb = parseFloat(stats["bb_a"] ?? "0")
            let hr = parseFloat(stats["hr_a"] ?? "0")
            let h = parseFloat(stats["h_a"] ?? "0")
            let er = parseFloat(stats["er"] ?? "0")
            let ipFloat = this.getIpAsFloat(ip)
            let k9 = (k/ipFloat) * 9
            let bb9 = (bb/ipFloat) * 9
            let hr9 = (hr/ipFloat) * 9
            let era = (er/ipFloat) * 9
            let whip = (h + bb)/ipFloat

            return <tr>
                <td>{season}</td>
                <td>{parseFloat(ip).toFixed(1)}</td>
                <td>{era.toFixed(2)}</td>
                <td>{k}</td>
                <td>{parseFloat(stats["p_b_war"]).toFixed(1)}</td>
                <td>{parseFloat(stats["p_f_war"]).toFixed(1)}</td>
            </tr>
        })

        if (seasons.filter(x => !!x).length === 0) {
            return null
        } else {
            return <div>
                <h3>Pitching Stats</h3>
                <table>
                    <th style={{width: "180px"}}>Year</th>
                    <th title={"Innings Pitched"}>IP</th>
                    <th title={"Earned Run Average (9 * ER / IP)"}>ERA</th>
                    <th title={"Strike Outs"}>SO</th>
                    <th title={"Wins Above Replacement computed by BBRef"}>bWAR</th>
                    <th title={"Wins Above Replacement computed by FanGraphs"}>fWAR</th>
                    {seasons}
                    {this.renderAggregatePitching(player)}
                </table>
            </div>
        }
    }

    renderAggregatePitching(player) {
        let ip = 0
        let k = 0
        let bb = 0
        let hr = 0
        let er = 0
        let h = 0
        let fWAR = 0
        let bWAR = 0
        let seasons = this.getAvailableYears()

        for (const season of seasons) {
            let currentSeason = player["seasons"][season]["raw"]
            if (currentSeason == null || currentSeason["p_f_war"] == null) {
                continue
            }

            ip += parseFloat(this.getIpAsFloat(currentSeason["ip"] ?? "0"))
            k += parseFloat(currentSeason["k"] ?? "0")
            bb += parseFloat(currentSeason["bb_a"] ?? "0")
            hr += parseFloat(currentSeason["hr_a"] ?? "0")
            er += parseFloat(currentSeason["er"] ?? "0")
            h += parseFloat(currentSeason["h_a"] ?? "0")
            fWAR += parseFloat(currentSeason["p_f_war"] ?? "0")
            bWAR += parseFloat(currentSeason["p_b_war"] ?? "0")
        }

        if (ip === 0) {
            return null
        } else {
            return [
                <tr className={"foot"}>
                    <td>Actual Totals</td>
                    <td>{ip.toFixed(0)}</td>
                    <td>{(((er/ip) * 9)).toFixed(2)}</td>
                    <td>{k}</td>
                    <td>{bWAR.toFixed(1)}</td>
                    <td>{fWAR.toFixed(1)}</td>
                </tr>,
                <tr className={"foot"}>
                    <td>Actual Averages</td>
                    <td>{(ip / seasons.length).toFixed(0)}</td>
                    <td>{(((er/ip) * 9)).toFixed(2)}</td>
                    <td>{(k / seasons.length).toFixed(2)}</td>
                    <td>{(bWAR / seasons.length).toFixed(1)}</td>
                    <td>{(fWAR / seasons.length).toFixed(1)}</td>
                </tr>
            ]
        }
    }

    handlePlayerClick(id, method) {
        logEvent(analytics, method + "_" + id)
        get(child(players, id)).then((snapshot) => {
            if (snapshot.exists()) {
                let player = snapshot.val()
                let defaultTab = player["Position"].toLowerCase() === "pitcher" ? 0 : 1
                this.setState({
                    selectedPlayer: player,
                    filters: {},
                    selectedKey: id,
                    searchResults: [],
                    selectedTab: defaultTab
                })
            } else {
                this.setState({
                    selectedPlayer: null,
                })
            }
        })
    }

    handleSearch(value) {
        if (value === null || value === "") {
            return
        }

        this.setState({searchValue: value});

        get(query(players, ...[orderByChild("search_value"), startAt(value.toLowerCase()), endAt(value.toLowerCase() + "\uf8ff")])).then((snapshot) => {
            if (snapshot.exists()) {
                console.log(Object.values((snapshot.val())))
                this.setState({
                    searchResults: Object.values(snapshot.val())
                })
            } else {
                this.setState({
                    searchResults: []
                })
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    resetFilters() {
        this.setState({
            filters: {},
            consecutiveBattingYears: "",
            battingMetric: null,
            pitchingMetric: null,
            startYear: null,
            endYear: null
        })
    }

    applyFilters() {
        if (this.state.startYear != null && this.state.endYear != null) {
            let startYear = parseInt(this.state.startYear)
            let endYear = parseInt(this.state.endYear)
            let yearDiff = endYear - startYear + 1
            let consBatting = this.state.consecutiveBattingYears
            let consPitching = this.state.consecutivePitchingYears

            if (endYear < startYear) {
                alert("The selected start year is after the selected end year")
                return
            } else if ((consBatting != null && parseInt(consBatting) === yearDiff) || (parseInt(consBatting) != null && consPitching === yearDiff)) {
                alert("The consecutive years parameter is equal to the number of selected years")
                return
            } else if ((consBatting != null && parseInt(consPitching) > yearDiff) || (parseInt(consPitching) != null && consPitching > yearDiff)) {
                alert("The consecutive years parameter is greater than the number of selected years")
                return
            }
        }
        this.setState({
            filters: {
                startYear: this.state.startYear,
                endYear: this.state.endYear,
                consecutiveBattingYears: this.state.consecutiveBattingYears,
                consecutivePitchingYears: this.state.consecutivePitchingYears,
                battingMetric: this.state.battingMetric,
                pitchingMetric: this.state.pitchingMetric
            }
        })
    }

    getEraAdjustedPitchingYears() {
        let yearCount = 0
        let years = this.getAvailableYears()
        for (let i in years) {
            let adjustedStats = this.state.selectedPlayer["seasons"][years[i]]["adjusted"]
            if (adjustedStats == null || adjustedStats["p_ef_war"] == null || adjustedStats["p_eb_war"] == null) {
                continue
            }

            yearCount++
        }
        return yearCount
    }

    getEraAdjustedBattingYears() {
        let yearCount = 0
        let years = this.getAvailableYears()
        for (let i in years) {
            let adjustedStats = this.state.selectedPlayer["seasons"][years[i]]["adjusted"]
            if (adjustedStats == null || adjustedStats["b_ef_war"] == null || adjustedStats["b_eb_war"] == null) {
                continue
            }

            yearCount++
        }
        return yearCount
    }

    getAvailableYears() {
        let allYears = Object.keys(this.state.selectedPlayer["seasons"])

        if (Object.values(this.state.filters).length === 0) {
            return allYears
        }

        let baseStartYear = this.state.filters.startYear == null ? allYears[0] : this.state.filters.startYear
        let baseEndYear = this.state.filters.endYear == null ? allYears[allYears.length - 1] : this.state.filters.endYear

        let allAvailableYears = this.filterYears(allYears, baseStartYear, baseEndYear)

        if (this.state.filters.consecutiveBattingYears != null && this.state.filters.consecutiveBattingYears !== 0 && this.state.filters.battingMetric != null) {
            return this.getBestConsecutiveYears(allAvailableYears, parseInt(this.state.filters.consecutiveBattingYears), this.state.filters.battingMetric.toLowerCase())
        } else if (this.state.filters.consecutivePitchingYears != null && this.state.filters.consecutivePitchingYears !== 0 && this.state.filters.pitchingMetric != null) {
            return this.getBestConsecutiveYears(allAvailableYears, parseInt(this.state.filters.consecutivePitchingYears), this.state.filters.pitchingMetric.toLowerCase())
        }
        return allAvailableYears
    }

    filterYears(years, start, end) {
        return years.filter((year) => {
            let currentYear = parseInt(year)

            if (start != null && currentYear < start) {
                return false
            }

            return !(end != null && currentYear > end);
        })
    }

    getBestConsecutiveYears(allAvailableYears, consecutiveYears, metric) {
        let value = 0
        let filteredYears = []
        allAvailableYears.forEach((year) => {
            let start = parseInt(year)
            let end = start + (consecutiveYears - 1)
            let testYears = this.filterYears(allAvailableYears, start, end)
            let type = metric.includes("era adjusted ") ? "adjusted" : "raw"
            let testMetric = metric.replace("era adjusted ", "")

            if (testYears.length === consecutiveYears) {
                let testVal = 0
                testYears.forEach((year) => {
                    let testSeason = this.state.selectedPlayer["seasons"][year]
                    if (type in testSeason) {
                        testVal += parseFloat(testSeason[type][this.getMetricKey(testMetric, type === "adjusted")])
                    }
                })

                if (aggregateStats.includes(testMetric)) {
                    if (testVal > value) {
                        value = testVal
                        filteredYears = testYears
                    }
                } else if (maxAvgStats.includes(testMetric)) {
                    let avgTestVal = testVal / consecutiveYears
                    if (avgTestVal > value) {
                        value = avgTestVal
                        filteredYears = testYears
                    }
                } else {
                    let avgTestVal = testVal / consecutiveYears
                    if (avgTestVal < value || value === 0) {
                        value = avgTestVal
                        filteredYears = testYears
                    }
                }
            }
        })
        return filteredYears
    }

    getMetricKey(metric, isEra = false) {
        switch (metric.toLowerCase()) {
            case "fwar":
                if (isEra) {
                    return this.state.filters.battingMetric != null ? "b_ef_war" : "p_ef_war"
                } else {
                    return this.state.filters.battingMetric != null ? "b_f_war" : "p_f_war"
                }
            case "bwar":
                if (isEra) {
                    return this.state.filters.battingMetric != null ? "b_eb_war" : "p_eb_war"
                } else {
                    return this.state.filters.battingMetric != null ? "b_b_war" : "p_b_war"
                }
            case "ba":
                return "avg"
            case "so":
                return isEra ? "so" : "k"
            default:
                return metric.toLowerCase()
        }
    }

    calculate_aggregate_batting_stats(stats) {
        if (stats['ab'] === 0 || stats['pa'] === 0) {
            return {obp: 0.000, avg: 0.000, slg: 0.000, iso: 0.000}
        } else {
            let obp = (stats["h"] + stats["bb"] + stats["hbp"]) / (stats["ab"] + stats["bb"] + stats["hbp"] + stats["sf"])
            let avg = stats["h"] / stats["ab"]
            return {obp: obp, avg: avg}
        }
    }

    createBbrefLink(id) {
        let startUrl = "http://www.baseball-reference.com/players/"
        return startUrl + id.charAt(0) + "/" + id + ".shtml"
    }

    createFangraphsLink(playerName, id) {
        let fgName = playerName.replace(' ', '-').toLowerCase()
        let startUrl = "http://www.fangraphs.com/players/"
        return startUrl + fgName + "/" + id
    }

    getIpAsFloat(ip) {
        const elements = ip.split(".")
        if (elements.length === 1) {
            return parseFloat(parseFloat(ip).toFixed(0))
        } else {
            if (elements[1] === "1") {
                return parseFloat(parseFloat(ip).toFixed(0)) + .33333333333
            } else if (elements[1] === "2") {
                return parseFloat(parseFloat(ip).toFixed(0)) + 0.666666666666
            } else {
                return parseFloat(parseFloat(ip)).toFixed(0)
            }
        }
    }
}

export function last(array) {
    if (array.length > 0) {
        return array[array.length - 1]
    } else {
        return "Unknown"
    }
}

export function first(array) {
    if (array.length > 0) {
        return array[0]
    } else {
        return "Unknown"
    }
}

export default App;
